import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

const ConfirmationDialog = ({
  title,
  description,
  confirmLabel,
  confirmColor,
  discardLabel,
  onDiscard,
  onConfirm,
  isSubmitting,
  ...rest
}) => (
  <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" {...rest}>
    {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
    <DialogContent>
      {description ? <DialogContentText id="alert-dialog-description">{description}</DialogContentText> : null}
    </DialogContent>
    <DialogActions>
      {discardLabel ? <Button onClick={onDiscard}>{discardLabel}</Button> : null}
      <LoadingButton variant="contained" color={confirmColor} onClick={onConfirm} loading={isSubmitting} autoFocus>
        {confirmLabel}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  confirmColor: PropTypes.string,
  discardLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onDiscard: PropTypes.func,
  onConfirm: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default ConfirmationDialog;
