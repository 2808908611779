import * as Sentry from '@sentry/react';
import React from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router';

const init = () => {
  if (process.env.REACT_APP_SENTRY_ENABLED) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          // TODO (mantovanig): in the future maybe to be put back to true
          maskAllText: false,
          maskAllInputs: false,
        }),
      ],
      replaysOnErrorSampleRate: 1.0,
      release: process.env.REACT_APP_VERSION,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

const captureException = (err) => {
  if (process.env.REACT_APP_SENTRY_ENABLED) {
    Sentry.withScope((scope) => {
      scope.setExtra('responseType', err?.response?.headers?.get('content-type'));
      scope.setExtra('url', err?.response?.url);
      scope.setExtra('status', err?.response?.status);
      Sentry.captureException(err);
    });
  } else {
    console.log(err);
  }
};

export default {
  init,
  captureException,
};
