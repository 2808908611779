import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';

export function WaReviewRedirect() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const target = searchParams.get('target');
    if (target) {
      window.location.href = target;
    }
  }, [searchParams]);

  return <LoadingScreen />;
}
