import { createContext, useEffect, useReducer, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  setPersistence,
  browserLocalPersistence,
  getAdditionalUserInfo,
  connectAuthEmulator,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
  getRedirectResult,
} from 'firebase/auth';
import { getDatabase, set, ref, get, child, onValue, connectDatabaseEmulator } from 'firebase/database';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

import { getBaseUrl } from 'utils/getBaseUrl';

import { PATH_AUTH } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

import { FIREBASE_API } from '../config';
import { userStatuses } from '../constants';
import useLocalStorage from '../hooks/useLocalStorage';

// ----------------------------------------------------------------------

export const firebaseApp = initializeApp(FIREBASE_API);

const FIREBASE_ERRORS = {
  'auth/wrong-password': 'La vecchia password è errata',
  'auth/weak-password': 'La password deve essere di almeno 6 caratteri',
};

const functions = getFunctions(firebaseApp);
const DB = getDatabase();
const AUTH = getAuth(firebaseApp);
AUTH.languageCode = 'it';

if (process.env.REACT_APP_EMULATOR_MODE_ENABLED) {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectDatabaseEmulator(DB, 'localhost', 9000);
  connectAuthEmulator(AUTH, 'http://localhost:9099');
  connectStorageEmulator(getStorage(), 'localhost', 9199);
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [tenantIdParam, setTenantIdParam] = useLocalStorage('tenantId', null);

  useEffect(() => {
    getRedirectResult(AUTH)
      .then(async (result) => {
        if (result) {
          const { isNewUser } = getAdditionalUserInfo(result);
          if (isNewUser) {
            const { user } = result;
            await createDBUser({
              uid: user.uid,
              username: user.displayName,
              email: user.email,
              photoURL: user.photoURL,
              tenantId: tenantIdParam,
            });
          }
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        const userRef = ref(DB, `/users/${user.uid}`);

        onValue(userRef, (snapshot) => {
          const data = snapshot.val() || {};

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {
                ...data,
                status: data.status,
                uid: user.uid,
                displayName: data.username,
                photoUrl: data.photoUrl,
                name: data.name,
                surname: data.surname,
                city: data.city,
                zipCode: data.zipCode,
                address: data.address,
                phoneNumber: data.phoneNumber,
                patients: data.patients,
                tenantId: data.tenantId,
                settings: data.settings,
                subscription: data.subscription,
                temporaryAlerts: data.temporaryAlerts,
                getIdToken: () => AUTH.currentUser.getIdToken(),
              },
            },
          });
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: false, user: null },
        });
      }
    });
  }, [dispatch]);

  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const signInWithGoogle = async (tenantId) => {
    try {
      if (tenantId) {
        setTenantIdParam(tenantId);
      }

      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });

      // Some mobile device doesn't support popup so we use redirect
      if (isMobile) {
        return setPersistence(AUTH, browserLocalPersistence).then(() => signInWithRedirect(AUTH, provider));
      }

      return setPersistence(AUTH, browserLocalPersistence)
        .then(() => signInWithPopup(AUTH, provider))
        .then((res) => {
          const { isNewUser } = getAdditionalUserInfo(res);
          if (isNewUser) {
            const { user } = res;

            createDBUser({
              uid: user.uid,
              username: user.displayName,
              email: user.email,
              photoURL: user.photoURL,
              tenantId,
            });
          }
          return res;
        });
    } catch (error) {
      console.error('Google sign in error:', error);
      throw error;
    }
  };

  const createDBUser = async (user) => {
    const dbRef = ref(DB);
    // read "name" because the tenanrt node is protected
    const tenantSnapshot = await get(child(dbRef, `tenants/${user.tenantId}/name`));

    set(ref(DB, `users/${user.uid}`), {
      username: user.username || '',
      name: user.name || '',
      surname: user.surname || '',
      email: user.email,
      photoURL: user.photoURL || '',
      pushNotification: { enabled: false },
      creationDate: new Date().getTime(),
      tenantId: tenantSnapshot.exists() && user.tenantId ? user.tenantId : '',
      status: userStatuses.INITIALIZING,
    });
  };

  const register = (email, password, firstName, lastName, tenantId) =>
    createUserWithEmailAndPassword(AUTH, email, password).then((res) => {
      const dbRef = ref(DB);

      get(child(dbRef, `users/${res.user.uid}`)).then((snapshot) => {
        if (!snapshot.exists()) {
          createDBUser({
            uid: res.user.uid,
            email,
            username: `${firstName} ${lastName}`,
            name: firstName,
            surname: lastName,
            tenantId,
          });
        }
      });

      return res;
    });

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const credential = EmailAuthProvider.credential(AUTH.currentUser.email, oldPassword);
      await reauthenticateWithCredential(AUTH.currentUser, credential);

      return updatePassword(AUTH.currentUser, newPassword);
    } catch (err) {
      throw new Error(FIREBASE_ERRORS[err.code] || err.message);
    }
  };

  const sendPasswordReset = (email) =>
    sendPasswordResetEmail(AUTH, email, {
      url: `${getBaseUrl()}${PATH_AUTH.login}`,
    });

  const logout = () => signOut(AUTH);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          status: state?.user?.status,
          creationDate: state?.user?.creationDate,
          photoURL: state?.user?.photoURL,
          displayName: state?.user?.displayName,
          name: state?.user?.name,
          surname: state?.user?.surname,
          city: state?.user?.city,
          zipCode: state?.user?.zipCode,
          address: state?.user?.address,
          phoneNumber: state?.user?.phoneNumber,
          patients: state?.user?.patients,
          integrations: state?.user?.integrations,
          features: state?.user?.features,
          tenantId: state?.user?.tenantId,
          settings: state?.user?.settings,
          subscription: state?.user?.subscription,
          getIdToken: state?.user?.getIdToken,
          temporaryAlerts: state?.user?.temporaryAlerts,
        },
        login,
        signInWithGoogle,
        register,
        logout,
        changePassword,
        sendPasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export { AuthProvider, useAuth };
