import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';

import { useTenantPublicData } from 'hooks/useTenant';
import { useAuth } from 'contexts/FirebaseContext';
import LogoPazientiSvg from './pazienti-logo.svg';
import LogoIconPazientiSvg from './pazienti-logo-icon.svg';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  onlySymbol: PropTypes.bool,
  forceDefault: PropTypes.bool,
};

export default function Logo({ disabledLink = false, onlySymbol = false, forceDefault = false, sx }) {
  const { data, isLoading } = useTenantPublicData();
  const { user } = useAuth();

  const userBusinessLogo = user?.settings?.businessInfo?.logoUrl;

  if (isLoading) {
    return <Skeleton width={onlySymbol ? 40 : 130} height={73} />;
  }

  const getLogo = () => {
    if (forceDefault) {
      return {
        logo: LogoPazientiSvg,
        logoSymbol: LogoIconPazientiSvg,
        logoName: 'Pazienti',
      };
    }

    if (userBusinessLogo) {
      return {
        logo: userBusinessLogo,
        logoSymbol: userBusinessLogo,
        logoName: user?.settings?.businessInfo?.name,
      };
    }

    if (data?.logo) {
      return {
        logo: data?.logo,
        logoSymbol: data?.logoSymbol,
        logoName: data?.name,
      };
    }

    return {
      logo: LogoPazientiSvg,
      logoSymbol: LogoIconPazientiSvg,
      logoName: 'Pazienti',
    };
  };

  const { logo, logoSymbol, logoName } = getLogo();

  if (disabledLink) {
    return (
      <Box sx={{ width: onlySymbol ? 40 : 130, ...sx }}>
        <img src={onlySymbol ? logoSymbol : logo} alt={`${logoName} logo`} style={{ maxWidth: '100%' }} />
      </Box>
    );
  }

  return (
    <RouterLink to="/">
      <Box sx={{ width: onlySymbol ? 40 : 130, ...sx }}>
        <img src={onlySymbol ? logoSymbol : logo} alt={`${logoName} logo`} style={{ maxWidth: '100%' }} />
      </Box>
    </RouterLink>
  );
}
