import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from './FirebaseContext';

const FlagsContext = createContext({});

const FlagsProvider = ({ children }) => {
  const { user } = useAuth();

  const flags = {
    calendarEnabled: user?.features?.calendar,
    reviewRequest: user?.features?.reviewRequest,
    treatmentRevenuesEnabled: user?.features?.treatmentRevenues,
    tawktoEnabled: user?.features?.tawkto ?? true,
    whatsappSelfSignup: user?.features?.whatsappSelfSignup,
    waitingListEnabled: user?.features?.waitingList,
    businessInfoLogoEnabled: user?.features?.businessInfoLogo,
  };

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>;
};

FlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useFeatureFlags = () => {
  const context = useContext(FlagsContext);
  return context;
};

export { FlagsProvider, useFeatureFlags };
