import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';

export function WaNumberRedirect() {
  const { number } = useParams();
  
  useEffect(() => {
    if (number) {
      window.location.href = `https://wa.me/${number}`;
    }
  }, [number]);

  return <LoadingScreen />;
} 